body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner-container {
  position: relative;
  overflow: hidden;
  /* overflow-x: hidden; */
}

.banner-container .blueShape {
  position: absolute;
  transition: 0.3s;
  /* display: none; */
  transform: rotate(275px);
  z-index: -6;
}

@media screen and (min-width: 10px) {
  .banner-container .blueShape {
    bottom: 80px;
    width: 50rem;
    right: -550px;
    display: block;
    transform: rotate(275px);
  }
}

@media screen and (min-width: 768px) {
  .banner-container .blueShape {
    bottom: -10px;
    width: 60rem;
    right: -550px;
    display: block;
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: 992px) {
  .banner-container .blueShape {
    top: -250px;
    width: 70rem;
    right: -480px;
    display: block;
  }
}
@media screen and (min-width: 1000px) {
  .banner-container .blueShape {
    top: -300px;
    width: 65rem;
    right: -350px;
  }
}
@media screen and (min-width: 1270px) {
  .banner-container .blueShape {
    width: 70rem;
    right: -250px;
    top: -350px;
  }
}
@media screen and (min-width: 1500px) {
  .banner-container .blueShape {
    width: 75rem;
    top: -350px;
    top: -420px;
    right: -250px;
  }
}
@media screen and (min-width: 1700px) {
  .banner-container .blueShape {
    width: 80rem;
    top: -480px;

    right: -200px;
  }
}

@media screen and (min-width: 2000px) {
  .banner-container .blueShape {
    right: -100px;
  }
}
@media screen and (min-width: 2500px) {
  .banner-container .blueShape {
    right: 300px;
  }
}

@media screen and (min-width: 2800px) {
  .banner-container .blueShape {
    right: 500px;
  }
}

.points span {
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--text-white);
  background-color: var(--dark-black);
  border-radius: 100px;
  font-size: 8px;
  margin-top: 3px;
}

.point-line {
  display: flex;
  gap: 20px;
}

.points-list {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 3 columns */
  gap: 20px; /* Adjust spacing between grid items */
}

@media screen and (min-width: 485px) {
  .points-list {
    grid-template-columns: repeat(2, 1fr); /* 3 columns */
  }
}

@media screen and (min-width: 992px) {
  .points-list {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
}

